.guestbookLeft {
  height: 100%;
  padding: 0.5rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
}

.guestbookLeft-welcom {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 15%;
  justify-content: center;
}

.guestbookLeft-welcom-name span {
  font-size: 2rem;
  font-weight: 600;
  background-color: pink;
}

.guestbookLeft-welcom-message {
  margin-top: 1rem;
}

.guestbookLeft-auth {
  height: 50%;
  display: flex;
  flex-direction: column;
}

.guestbookLeft-auth-info {
  display: flex;
  flex-direction: column;
}

.guestbookLeft-auth-info span {
  margin-bottom: 1rem;
}

.guestbookLeft-auth-btnDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.guestbookLeft-auth-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 5rem;
  width: 5rem;
  border: none;
}
.guestbookLeft-auth-btn img:hover {
  height: 5.5rem;
  width: 5.5rem;
  cursor: pointer;
}

.guestbookLeft-auth-btn:hover {
  border: 0.1rem solid black;
}

.guestbookLeft-auth-btn img {
  height: 5rem;
  width: 5rem;
}

.guestbookLeft-auth-input-info {
  display: flex;
  flex-direction: column;
}

.guestbookLeft-auth-input-info span {
  margin-bottom: 1rem;
}

.guestbookLeft-auth-input-info span:nth-of-type(3) {
  font-size: 0.9rem;
}

.guestbookLeft-auth-input-info input {
  height: 2rem;
  font-size: 1.2rem;
}

.guestbookLeft-auth-input-info button {
  margin-bottom: 1rem;
}

.guestbookLeft-auth-input-info span:nth-of-type(4) {
  font-size: 0.8rem;
  color: gray;
}

.guestbookLeft-auth-input-info button:hover {
  cursor: pointer;
}

/* guestbookRight */

.guestbookRight {
  height: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

/* 글쓰기 영역 */

.guestbook-write {
  height: 25%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin-bottom: 3rem;
  border-top: 0.1rem solid gray;
  border-bottom: 0.1rem solid gray;
  background-color: rgb(235, 235, 235);
}

.guestbook-write-body {
  display: flex;
  flex-direction: row;
  height: 80%;
}

.guestbook-write-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 15%;
  border: 0.01rem solid grey;
  background-color: white;
}

.guestbook-write-img:hover {
  cursor: pointer;
  border: 0.1rem solid black;
}

.gusetbook-modal-img:hover {
  cursor: pointer;
  border: 0.1rem solid black;
}

.guestbook-write-message {
  width: 85%;
  padding: 0.3rem;
  margin-left: 0.3rem;
  height: 100%;
  border: 0.01rem solid grey;
  background-color: white;
}

.guestbook-write-message textarea {
  width: 100%;
  height: 100%;
  resize: none;
  border: none;
  padding: 0.3rem;
}

.guestbook-write-btn {
  height: 20%;
  display: flex;
  margin-left: auto;
  align-items: center;
}

.guestbook-write-btn button {
  display: flex;
  height: 70%;
  justify-content: center;
  align-items: center;
}

/* 방명록 글 */

.guestbook-posts {
  height: 75%;
}

.guestbook-items {
  margin-bottom: 3rem;
  border-top: 0.1rem solid grey;
}

.guestbook-posts-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(235, 235, 235);
  padding: 0.3rem;
}

.guestbook-posts-name {
  margin-left: 2rem;
  font-size: 1.3rem;
  color: rgb(58, 58, 203);
  font-weight: 500;
}

.guestbook-posts-date {
  margin-left: 10rem;
  color: rgba(20, 44, 52, 0.454);
}

.guestbook-posts-deleteBtn {
  margin-left: auto;
}

.guestbook-posts-body {
  display: flex;
  flex-direction: row;
  margin: 0.3rem;
  height: 7rem;
}

.posts-body-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
}

.posts-body-message {
  width: 85%;
  padding: 0.3rem;
  margin-left: 0.3rem;
  overflow-y: auto;
  white-space: pre-wrap;
}

.guestbook-posts-footer {
  margin-left: 1rem;
}

/* 맥북 14인치 */
@media screen and (min-width: 1366px) and (max-width: 1512px) {
  .guestbookLeft-welcom-message {
    font-size: 0.71rem;
  }
  .guestbookLeft-auth-info {
    font-size: 0.7rem;
  }
  .guestbookLeft-auth-btn {
    height: 4rem;
    width: 4rem;
  }
  .guestbookLeft-auth-btn img {
    height: 4rem;
    width: 4rem;
  }
  .guestbookLeft-auth-btn img:hover {
    height: 4.5rem;
    width: 4.5rem;
  }

  .guestbook-posts-name {
    font-size: 1rem;
  }
  .guestbook-posts-date {
    font-size: 0.8rem;
  }
  .posts-body-message {
    font-size: 0.9rem;
  }
  .guestbook-posts-footer {
    font-size: 0.85rem;
  }
}

/* 아이패드 11인치 */
@media screen and (min-width: 768px) and (max-width: 1366px) {
  .guestbookLeft-welcom-name span {
    font-size: 1.7rem;
  }
  .guestbookLeft-welcom-message {
    font-size: 0.47rem;
  }
  .guestbookLeft-auth-info {
    font-size: 0.6rem;
  }
  .guestbookLeft-auth-btn {
    height: 3rem;
    width: 3rem;
  }
  .guestbookLeft-auth-btn img {
    height: 3rem;
    width: 3rem;
  }
  .guestbookLeft-auth-btn img:hover {
    height: 3.5rem;
    width: 3.5rem;
  }
  .guestbook-write-message textarea {
    font-size: 0.8rem;
  }

  .guestbook-posts-name {
    font-size: 0.8rem;
  }
  .guestbook-posts-date {
    font-size: 0.6rem;
  }
  .posts-body-message {
    font-size: 0.8rem;
  }
  .guestbook-posts-footer {
    font-size: 0.7rem;
  }
}
