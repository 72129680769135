.update-version-item {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.update-version-main {
  font-size: 1.5rem;
  color: navy;
}

.update-sub-items {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.1rem;
}

.update-sub-version {
  width: 8%;
  margin-left: 1rem;
}

.update-sub-change {
  width: 77%;
}

.update-sub-date {
  width: 15%;
}

.notice-musicplayer-div1 {
  display: flex;
  flex-direction: row;
}

.notice-musicplayer-div2 {
  display: flex;
  flex-direction: column;
}

.notice-musicplayer-imgdiv {
  margin-left: 1.5rem;
}
