.jukboxRight {
  height: 100%;
  padding: 10px;
  overflow-y: auto;
}

.music__table {
  height: 80%;
  display: flex;
  flex-direction: column;
}

.music-play-all {
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  margin-left: 1.7rem;
  padding-bottom: 1rem;
}

.music-play-all button {
  display: flex;
  align-items: center;
  height: 2rem;
  font-size: 1.5rem;
}

.music-play-all button:hover {
  cursor: pointer;
}

.music-play-all button:active {
  color: #6e2c17;
}

.music-row-header {
  background-color: lightgray;
}

.music-render {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-bottom: dotted;
  padding: 5px;
  white-space: nowrap;
}

.music-render div {
  display: flex;
  justify-content: center;
}

.music-render button:hover {
  cursor: pointer;
}

.music-render button:active {
  color: #6e2c17;
}

.music-render > div:nth-child(1) {
  width: 5%;
}
.music-render > div:nth-child(2) {
  width: 5%;
}
.music-render > div:nth-child(3) {
  width: 50%;
}
.music-render > div:nth-child(4) {
  width: 25%;
}

/* 맥북 14인치 */
@media screen and (min-width: 1366px) and (max-width: 1512px) {
  .music-play-all button {
    height: 1.5rem;
    font-size: 1rem;
  }
  .music-render {
    font-size: 0.8rem;
  }
  .music-render-btn {
    height: 1.2rem;
    width: 1.5rem;
    font-size: 0.75rem;
  }
}

/* 아이패드 11인치 */
@media screen and (min-width: 768px) and (max-width: 1366px) {
  .music-play-all button {
    height: 1.2rem;
    font-size: 0.8rem;
  }
  .music-render {
    font-size: 0.7rem;
  }
  .music-render-btn {
    height: 0.8rem;
    width: 1.3rem;
    font-size: 0.5rem;
  }
}
