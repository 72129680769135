.project-right {
  height: 100%;
  border-radius: 1rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.project-items {
  border: 0.1rem solid black;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.project-items div {
  margin-bottom: 0.1rem;
}

.project-title {
  font-size: 2rem;
  font-weight: 600;
  border-bottom: 0.1rem dotted gray;
}

.project-where {
  font-size: 0.9rem;
  color: black;
}

.project-when {
  color: gray;
}

.project-sub {
  font-size: 1.5rem;
}

.project-exp {
  margin-left: 0.5rem;
}

.project-site {
  margin: 0.5rem;
}

/* 맥북 14인치 */
@media screen and (min-width: 1366px) and (max-width: 1512px) {
  .project-title {
    font-size: 1.8rem;
  }
  .project-when {
    font-size: 0.8rem;
  }
  .project-sub {
    font-size: 1.3rem;
  }
  .project-exp {
    font-size: 0.7rem;
  }
}

/* 아이패드 11인치 */
@media screen and (min-width: 768px) and (max-width: 1366px) {
  .project-title {
    font-size: 1.4rem;
  }
  .project-when {
    font-size: 0.6rem;
  }
  .project-sub {
    font-size: 1rem;
  }
  .project-exp {
    font-size: 0.5rem;
  }
}
