* {
  box-sizing: border-box;
  word-wrap: break-word;
}

li {
  list-style: inside;
  list-style: inside;
}

.background {
  width: 100vw;
  height: 100vh;
  padding: 3% 6% 3% 15%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
}

.outerBlue {
  width: 100%;
  height: 100%;
  background-color: #55b2d4;
  border: 2px solid;
  border-radius: 15px;
  padding: 15px;
}

.whiteSolid {
  width: 100%;
  height: 100%;
  border: 2px dashed lightgray;
  border-radius: 15px;
  padding: 10px;
  display: flex;
}

.outerbox {
  width: 100%;
  height: 100%;
  border: 2px solid;
  border-radius: 15px;
  background-color: #eeeeee;
}

.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 0.3%;
}

.wrapper__left {
  width: 20%;
  height: 100%;
  padding: 0.3rem;
}

.main__left {
  height: 100%;
  border: 1px solid;
  border-radius: 15px;
  padding: 0.5rem;
  margin-right: 5px;
  background-color: white;
}

.wrapper__right {
  width: 80%;
  height: 100%;
  padding: 0.3rem;
}

.main__right {
  height: 100%;
  border: 1px solid;
  border-radius: 15px;
  padding: 1rem;
  margin-right: 10px;
  background-color: white;
}

/* 네이게이션바 */
.navigation {
  /* width: 72px; */
  height: 23rem;
  display: flex;
  flex-direction: column;
  position: relative;
  right: 3.13rem;
  top: 3.5rem;
}

.navigation__item {
  width: 60px;
  height: 45px;
  background-color: #298eb5;
  border: 1px solid black;
  color: white;
  border-radius: 0rem 0.5rem 0.5rem 0rem;
  border-left: none;
  font-size: 15px;
  text-align: center;
  line-height: 40px;
  text-decoration: none;
  margin-bottom: 0.05rem;
}

.navigation__item.active {
  color: black;
  background-color: white;
}

/* 사이드 */
.side {
  width: 15%;
  /* padding: 1rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.music {
  height: 15%;
  width: 90%;
  border-radius: 1rem;
  padding: 0.5rem 0.5rem 0.3rem 0.5rem;
  background-color: whitesmoke;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.weather {
  margin-top: 9rem;
  height: 24%;
  width: 75%;
  border-radius: 1rem;
  padding: 0.5rem;
  background-color: whitesmoke;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.time {
  height: 9.5%;
  width: 70%;
  margin-top: 10rem;
  border-radius: 1rem;
  padding: 0.5rem;
  overflow: hidden;
  background-color: whitesmoke;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.sideTime-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.nowTime {
  font-size: 2rem;
}

/* geo */
.geolocation span {
  font-size: 2rem;
}

.linkto {
  background-color: lightpink;
}

.Information-icon:hover {
  cursor: help;
  color: pink;
}

/* Page Not Found */
.notfound {
  border-radius: 1rem;
  border: 0.1rem solid black;
  height: 100%;
  width: 100%;
}

.notfound-img {
  border-radius: 1rem;
}

/* 맥북 14인치 */
@media screen and (min-width: 1366px) and (max-width: 1512px) {
  .nowTime {
    font-size: 1.8rem;
  }
  .toDay {
    font-size: 0.8rem;
  }

  .geolocation span {
    font-size: 1.5rem;
  }
}

/* 아이패드 11인치 */
@media screen and (min-width: 768px) and (max-width: 1366px) {
  .nowTime {
    font-size: 1.4rem;
  }
  .toDay {
    font-size: 0.6rem;
  }

  h2 {
    font-size: 1.2rem;
  }
  .geolocation span {
    font-size: 1rem;
  }
}
