.sideWeather-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: whitesmoke;
  border-radius: 1rem;
  padding: 0.5rem;
  overflow: hidden;
}

.sideWeather-location {
  /* background-color: red; */
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 1rem;
}

.sideWeather-InfoBox {
  display: flex;
  flex-direction: column;
}

.sideWeather-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sideWeather-info > div {
  margin-left: 0.7rem;
}

/* 맥북 14인치 */
@media screen and (min-width: 1366px) and (max-width: 1512px) {
  .sideWeather-box {
    padding: 0.3rem;
  }
  .sideWeather-location {
    font-size: 1.6rem;
  }
  .sideWeather-info > div {
    margin-left: 0.3rem;
  }
  .sideWeather-InfoBox {
    font-size: 0.9rem;
  }
}

/* 아이패드 11인치 */
@media screen and (min-width: 768px) and (max-width: 1366px) {
  .sideWeather-box {
    padding: 0.3rem;
  }
  .sideWeather-location {
    font-size: 1.3rem;
  }
  .sideWeather-info > div {
    margin-left: 0.2rem;
  }
  .sideWeather-InfoBox {
    font-size: 0.7rem;
  }
}
