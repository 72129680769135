/* IntroLeftPage */
.wrapper__left__header {
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img_profile img {
  max-width: 170px;
  max-height: auto;
}

.wrapper__left__body {
  height: 60%;
  display: flex;
  flex-direction: column;
}

.img_profile {
  border-radius: 1rem;
  overflow: hidden;
}

.img_profile img {
  max-width: 100%;
  border-radius: 1rem;
}

.introduce {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.welcomeMessage {
  display: flex;
  flex-direction: column;
  font-size: 0.86rem;
  padding-left: 0.5rem;
  height: 100%;
}
.welcomeMessage span {
  margin-bottom: 0.1rem;
}

.wrapper__left__footer {
  height: 10%;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  margin-left: 0.5rem;
}

.emailAddress {
  color: navy;
  font-size: 0.7rem;
  font-weight: 1000;
  margin-bottom: 0.5rem;
  overflow: hidden;
}

/* 사이트 */

.sites {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* 왼쪽 네비 페이지 컴포넌트 */

.LeftNavPage {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.LeftNavPage-Nav {
  background-color: white;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  font-size: 1.4rem;
  text-decoration: none;
  color: navy;
  padding: 0.4rem;
}

.LeftNavPage-Nav.active {
  background-color: lightblue;
}

/* 맥북 14인치 */
@media screen and (min-width: 1366px) and (max-width: 1512px) {
  .wrapper__left__footer {
    margin-left: 0.3rem;
  }

  .emailAddress {
    font-size: 0.5rem;
  }
  .welcomeMessage {
    font-size: 0.8rem;
  }
  .sites {
    font-size: 0.8rem;
  }
  .sites-select {
    font-size: 0.6rem;
  }

  .LeftNavPage-Nav {
    font-size: 1rem;
  }
}

/* 아이패드 11인치 */
@media screen and (min-width: 768px) and (max-width: 1366px) {
  .wrapper__left__footer {
    margin-left: 0.05rem;
  }

  .emailAddress {
    font-size: 0.35rem;
  }
  .welcomeMessage {
    font-size: 0.77rem;
  }
  .sites {
    font-size: 0.5rem;
  }
  .sites-select {
    font-size: 0.37rem;
  }

  .LeftNavPage-Nav {
    font-size: 0.7rem;
  }
}
