/* HomeRight */
.home-right {
  height: 100%;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home-right-top {
  height: 25%;
}

.home-right-notice {
  height: 20%;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 0.5rem;
}

.home-right-content {
  height: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.home-right-notice-content {
  width: 50%;
  border: 0.1rem solid black;
  border-radius: 1rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.notice-itme {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0.2rem;
  font-size: 0.9rem;
}

.notice-item-title {
  width: 75%;
}

.notice-item-date {
  width: 25%;
  color: grey;
}

/* 게시판 게시물 숫자 요약 */

.home-right-pageSummary {
  width: 45%;
  /* background-color: rgb(219, 216, 216); */
  display: flex;
  flex-wrap: wrap;
  border: 1px dotted gray;
}

.home-right-pageSummary > div {
  border: 1px dotted gray;
  padding: 0.7rem;
  width: 50%;
  display: flex;
  align-items: center;
}

.home-pageSummary-link {
  text-decoration: none;
  color: rgb(43, 43, 43);
}

.home-pageSummary-link span {
  color: navy;
}

/* 공지사항 내용 칸 */
.home-right-notice-detail {
  height: 70%;
  border: 0.1rem solid black;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: overlay;
}

/* 맥북 14인치 */
@media screen and (min-width: 1366px) and (max-width: 1512px) {
  .home-right-notice-content {
    padding: 0.4rem;
  }
  .notice-itme {
    font-size: 0.7rem;
  }
  .home-right-pageSummary > div {
    padding: 0.3rem;
  }
  .home-right-notice-detail {
    font-size: 0.78rem;
  }
}

/* 아이패드 11인치 */
@media screen and (min-width: 768px) and (max-width: 1366px) {
  .home-right-notice-content {
    padding: 0.3rem;
  }
  .notice-itme {
    font-size: 0.57rem;
  }
  .home-right-pageSummary > div {
    padding: 0.1rem;
    font-size: 0.8rem;
  }
  .home-right-notice-detail {
    font-size: 0.65rem;
  }
}
