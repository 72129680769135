.sideMusic {
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  background-color: whitesmoke;
}

.sideMusic-songinfo {
  width: 100%;
  height: 35%;
}
.flow-text {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s;
  font-size: 1.5rem;
  font-weight: bold;
}
.flow-text:hover {
  color: #9d9993;
}
.flow-text:hover .flow-wrap {
  animation-play-state: paused;
  cursor: pointer;
}
.flow-wrap {
  animation: textLoop 10s linear infinite;
  padding-right: 1.5rem;
}

@keyframes textLoop {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.sideMusic-mid {
  height: 35%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.sideMusic-control-btn {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-wrap: wrap; */
  overflow: hidden;
}

.sideMusic-control-btn button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  font-size: 1.5rem;
  border: none;
  background: none;
  color: black;
}
.sideMusic-control-btn button:hover {
  cursor: pointer;
  font-size: 2rem;
  color: black;
}

.sideMusic-control-btn button:active {
  color: #6e2c17;
}

.sideMusic-control-btn button:disabled {
  color: gray;
  pointer-events: none;
}

.sideMusic-volume {
  height: 30%;
  overflow: hidden;
}

.sideMusic-reactPlayer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.btn-active {
  color: #6e2c17;
  fill: #7a4c3e;
}

/* 맥북 14인치 */
@media screen and (min-width: 1366px) and (max-width: 1512px) {
  .sideMusic-control-btn button {
    width: 1.3rem;
    height: 1.3rem;
    font-size: 1.3rem;
  }
  .sideMusic-control-btn button:hover {
    font-size: 1.7rem;
  }
}

/* 아이패드 11인치 */
@media screen and (min-width: 768px) and (max-width: 1366px) {
  .sideMusic-control-btn button {
    width: 1.2rem;
    height: 1.2rem;
    font-size: 1.2rem;
  }
  .sideMusic-control-btn button:hover {
    font-size: 1.6rem;
  }
}

/* ======================================== 플레이어 용 ======================================== */
@keyframes PlayerBackgroundAni {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.m-background {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to bottom, #ffcccc, #ff99cc);
  background: linear-gradient(-45deg, #fff6b7, #f092bc, #8795e6, #c2ffd8);
  background-size: 400% 400%;
  animation: PlayerBackgroundAni 15s ease infinite;
}

.mobile-music {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}

/* top */
.top {
  height: 10%;
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.repeat {
  width: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.repeat-icon {
  width: 4rem;
}

.cm-music {
  width: 60%;
  display: flex;
}

.cm-music-txt {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  font-weight: 600;
  color: transparent;
  background: linear-gradient(180deg, #d9a7c7 0%, #fffcdc 100%);
  background-clip: text;
  padding: 1rem;
}

.playing-list {
  width: 10%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.playing-list-icon {
  width: 4rem;
}

/* body */
.body {
  height: 75%;
  width: 90%;
  display: flex;
  flex-direction: column;
}

/* body1 */
.m-show-box {
  height: 65%;
}

.m-notice {
  padding: 1rem;
  font-size: 1.5rem;
  color: white;
}

/* playlist */
.m-show-playlist {
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  background-color: rgb(0, 0, 0, 0.65);
  list-style: none;
  display: flex;
  flex-direction: column;
}

.m-showing-playlist {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  color: white;
  padding: 1rem;
}

/* playingList */
.m-show-playingList {
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  background-color: rgb(0, 0, 0, 0.65);
  list-style: none;
  display: flex;
  flex-direction: column;
}

.m-showing-playingList {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
}

.m-playingList-title {
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  color: white;
}

.m-playingList-artist {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  color: rgb(206, 203, 203);
}

/* body2 */
.song-info {
  height: 15%;
}

.song-info-mobile {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.song-title {
  height: 60%;
  display: flex;
  align-items: end;
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
}

.song-artist {
  height: 40%;
  display: flex;
  align-items: top;
  font-size: 0.8rem;
  color: rgb(200, 200, 200);
}

.song-info-player {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s;
  font-size: 4rem;
  font-weight: bold;
  color: white;
}
.song-info-player2 {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s;
  font-size: 4rem;
  font-weight: bold;
  color: #9d9993;
}

.song-info-player:hover,
.song-info-player2:hover {
  color: pink;
}

.song-info-player-wrap {
  animation-play-state: paused;
  cursor: pointer;
}
.song-info-player-wrap {
  animation: textLoop 10s linear infinite;
  padding-right: 7rem;
}

/* body3 */
.controls {
  height: 20%;
  display: flex;
  flex-direction: column;
}

.play-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50%;
}

.time-start {
  display: flex;
  justify-content: center;
  width: 15%;
  color: white;
  font-size: 1rem;
}

.play-bar-range {
  width: 70%;
}

.play-bar-range::-webkit-slider-thumb {
  width: 1.5rem;
  height: 1.5rem;
  pointer-events: all;
}

.play-bar-range::-moz-range-thumb {
  width: 1.5rem;
  height: 1.5rem;
  pointer-events: all;
}

.play-bar-range::-ms-thumb {
  width: 1.5rem;
  height: 1.5rem;
  pointer-events: all;
}

.time-end {
  display: flex;
  justify-content: center;
  width: 15%;
  color: white;
  font-size: 1rem;
}

/* 버튼 */
/* == 모바일 == */
.play-btns {
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.play-btns button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  width: 3rem;
  height: 3rem;
  border: none;
  background: none;
  color: rgb(206, 203, 203);
}

/* == 플레이어 == */

.play-btns-p {
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.play-btns-p button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  width: 6rem;
  height: 5rem;
  border: none;
  background: none;
  color: rgb(206, 203, 203);
}

.play-btns-p button:hover {
  font-size: 5rem;
}

.play-btns,
.play-btns-p button:active {
  color: white;
}

.play-btns,
.play-btns-p button:disabled {
  color: gray;
}

.mobile-music .btn-active {
  color: pink;
}

/* 볼륨 bar (모바일은 안보임) */
.volumeBar {
  margin-top: 2rem;
  width: 80%;
}
